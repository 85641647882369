import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import { AppReleaseMetadata, PrereleaseMetadata } from '@/data/release/AppReleaseMetadata';
import DataWorker from '@/data/storage/DataWorker';

export const useReleasesStore = defineStore('Releases', () => {
  const releaseMetadata: Ref<Record<string, AppReleaseMetadata[]>> = ref({});

  function sortedReleasesForApp(appId: string): AppReleaseMetadata[] {
    return releaseMetadata.value[appId]?.slice().sort((a, b) => { return b.created - a.created; }) || [];
  }

  const environmentVariablesUsedInAReleaseByApp: ComputedRef<Record<string, string[]>> = computed(() => {
    const usedVars : Record<string, string[]> = {};
    for (const appId in releaseMetadata.value) {
      const variableNames: Set<string> = new Set();
      const appReleases = releaseMetadata.value[appId];
      appReleases.forEach(release => {
        release.environmentVariables.forEach((variable) => variableNames.add(variable));
      });
      if (variableNames.size > 0) {
        usedVars[appId] = Array.from(variableNames);
      }
    }
    return usedVars;
  });

  function setAppReleaseMetadata(details: { appReleaseMetadata: AppReleaseMetadata[], fullRefresh: boolean }): void {
    log.debug(`Setting ${details.appReleaseMetadata.length} app releases`);
    // If it's a full refresh, then delete any records that aren't in the updated object
    const updatesByApp: Record<string, AppReleaseMetadata> = {};
    details.appReleaseMetadata.forEach(release => {
      updatesByApp[release.appId] = release;
      const appReleases: AppReleaseMetadata[] = releaseMetadata.value[release.appId] || [];
      const releaseIndex = appReleases.findIndex(r => r.id === release.id);
      if (releaseIndex >= 0) {
        appReleases.splice(releaseIndex, 1, release);
      } else {
        appReleases.push(release);
      }

      if (appReleases.length === 1) {
        Vue.set(releaseMetadata.value, release.appId, appReleases);
      }
    });

    if (details.fullRefresh) {
      const removedAppIds: string[] = Object.keys(releaseMetadata.value).filter((appId: string) => {
        return !!updatesByApp[appId];
      });
      for (const removedId of removedAppIds) {
        if (releaseMetadata.value[removedId]) {
          log.debug(`Removing releases for app [${removedId}]`);
          Vue.delete(releaseMetadata.value, removedId);
        }
      }
    }

    log.debug(`After set ${Object.keys(releaseMetadata.value).length} apps have releases`);
  }

  async function releaseVersion(releaseInfo: PrereleaseMetadata): Promise<AppReleaseMetadata> {
    return await DataWorker.instance().dispatch('AppReleases/releaseAppVersion', releaseInfo);
  }

  async function updateReleaseMetadataLabel(releaseMetadata: AppReleaseMetadata): Promise<AppReleaseMetadata> {
    return await DataWorker.instance().dispatch('AppReleases/updateAppReleaseMetadataLabel', releaseMetadata);
  }

  return {
    releaseMetadata,
    environmentVariablesUsedInAReleaseByApp,
    sortedReleasesForApp,
    updateReleaseMetadataLabel,
    releaseVersion,
    setAppReleaseMetadata,
  };
});
