import { FormattingConfig } from '@/components/tasks/conditionalformatting/conditional-formatting';
import { UiOption } from '@/data/datatypes/components/UiOption';
import { DisplayFormatConfig } from '@/data/tasks/displayformatting/DisplayFormatting';

import { CustomViewEmbeddedConfig } from './customviews/CustomViewUIConfig';
import { InfoBoxAttributeIcon, MultipleChoiceOption, RadioGroupStyling } from './customviews/DataSetConfig';

export enum AttributeType {
  TEXT = 'text',
  PASSWORD = 'password',
  DATE = 'date',
  TIME = 'time',
  SELECT = 'select',
  AUTOCOMPLETE = 'autocomplete',
  RECORD = 'task',
  CHECKBOX = 'checkbox',
  NUMBER = 'number',
  USER = 'user',
  TRACK = 'track',
  PERCENT = 'percent',
  CONTENT = 'content',
  IMAGE = 'image',
  URL = 'url',
  LONG_TEXT = 'longText',
  ARTICLE = 'article',
  FORMULA = 'formula',
  RULESET = 'ruleset',
  CREATED_DATE = 'createdDate',
  LAST_UPDATED = 'lastUpdated',
  CREATED_BY = 'createdBy',
  LAST_UPDATED_BY = 'lastUpdatedBy',

  /** the following attribute types are internal and cannot be added as part of the schema **/
  // Used for the tasks perspective to show a column placeholder for attributes that don't exist across all tables
  PLACEHOLDER = 'placeholder',
  // Formula attributes
  TYPE = 'type', // used for casting i.e. {someNumberField} as percent
  TIME_UNIT = 'timeUnit', // used for date functions i.e DATEADD({myDate}, 5, days) where days is type TIME_UNIT
  ACTION = 'action', // used to represent custom view action fields that don't correspond to anything in the schema.
  LINE_BREAK = 'lineBreak', // used to add new lines in form layouts
  DESCRIPTION = 'description', // used to add descriptions to form sections
  SEPARATOR_LINE = 'separatorLine', // used to add a separator line
  LIST_TABLE = 'listTable', // Renders a table component within a form
  INFO_BOX = 'infoBox',
  FORM_BUTTON = 'formButton'
}

export const readOnlyAttributeTypes = [
  AttributeType.FORMULA,
  AttributeType.RULESET,
  AttributeType.CREATED_DATE,
  AttributeType.LAST_UPDATED,
  AttributeType.CREATED_BY,
  AttributeType.LAST_UPDATED_BY,
  AttributeType.PLACEHOLDER,
  AttributeType.ACTION,
  AttributeType.DESCRIPTION,
  AttributeType.INFO_BOX,
];

export enum AttributeComparisonValidity {
  NO_OP = 0,
  EXACT = 1,
  COMPATIBLE = 2,
  NOT_VALID = 4
}

export enum DateAttributeOptionType {
  INCLUDE_TIME = 'INCLUDE_TIME',
}

export enum NumberAttributeOptionType {
  MIN = 'MIN',
  MAX = 'MAX'
}

export enum PercentAttributeOptionType {
  PRECISION = 'PRECISION',
  MIN = 'MIN',
  MAX = 'MAX'
}

export enum TaskAttributeOptionType {
  TABLE_RESTRICTION = 'TABLE_RESTRICTION',
  DISPLAY_ATTRIBUTE = 'DISPLAY_ATTR',
}

export enum FormulaAttributeOptionType {
  FORMULA = 'FORMULA',
}

export enum RulesetAttributeOptionType {
  RULESET = 'RULESET',
}

export interface Attribute {
  id?: string;
  name: string;
  type: AttributeType;
  options?: Record<string, string>;
  hints?: Record<string, string>;
  conditionalFormatting?: FormattingConfig[];
  displayFormat?: DisplayFormatConfig;

  // These properties are used client-side when rendering custom views:
  displayName?: string;
  clickable?: boolean;
  // This currently stores either the buttonText of actions or the textContent of description fields
  textContent?: string;
  // This stores the rich text content of 'info box' fields
  htmlContent?: string;
  showHeaderText?: boolean;
  tooltip?: string;
  style?: Record<string, string>;
  hoverStyle?: Record<string, string>;
  // This is the same as the options property but used specifically for custom views
  // where the options need to maintain a certain order, which isn't possible using a Record
  dataSetOptions?: MultipleChoiceOption[] | undefined;
  embeddedComponentConfig?: CustomViewEmbeddedConfig;
  // used for info attributes
  infoBoxIcon?: InfoBoxAttributeIcon;

  radioGroupStyling?: RadioGroupStyling;
}

export const ATTRIBUTE_TYPE_OPTIONS: UiOption[] = [
  {
    id: 'text',
    value: AttributeType.TEXT,
    label: 'Short text'
  },
  {
    id: 'longText',
    value: AttributeType.LONG_TEXT,
    label: 'Long text',
  },
  {
    id: 'number',
    value: AttributeType.NUMBER,
    label: 'Number'
  },
  {
    id: 'percent',
    value: AttributeType.PERCENT,
    label: 'Percent',
  },
  {
    id: 'date',
    value: AttributeType.DATE,
    label: 'Date'
  },
  {
    id: 'time',
    value: AttributeType.TIME,
    label: 'Time'
  },
  {
    id: 'checkbox',
    value: AttributeType.CHECKBOX,
    label: 'Checkbox'
  },
  {
    id: 'select',
    value: AttributeType.SELECT,
    label: 'Multiple choice selection'
  },
  {
    id: 'image',
    value: AttributeType.IMAGE,
    label: 'Workspace image',
  },
  {
    id: 'url',
    value: AttributeType.URL,
    label: 'URL',
  },
  {
    id: 'task',
    value: AttributeType.RECORD,
    label: 'Records'
  },
  {
    id: 'user',
    value: AttributeType.USER,
    label: 'Users'
  },
  {
    id: 'content',
    value: AttributeType.CONTENT,
    label: 'Assets',
  },
  {
    id: 'track',
    value: AttributeType.TRACK,
    label: 'Workspaces',
  },
  {
    id: 'article',
    value: AttributeType.ARTICLE,
    label: 'Knowledge articles',
  },
  {
    id: 'formula',
    value: AttributeType.FORMULA,
    label: 'Formula',
  },
  {
    id: 'ruleset',
    value: AttributeType.RULESET,
    label: 'Ruleset',
  },
  {
    id: 'createdDate',
    value: AttributeType.CREATED_DATE,
    label: 'Created date',
  },
  {
    id: 'lastUpdated',
    value: AttributeType.LAST_UPDATED,
    label: 'Last updated date',
  },
  {
    id: 'createdBy',
    value: AttributeType.CREATED_BY,
    label: 'Created by',
  },
  {
    id: 'lastUpdatedBy',
    value: AttributeType.LAST_UPDATED_BY,
    label: 'Last updated by',
  },
];
