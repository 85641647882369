import { FormulaConditionArgs } from '@/components/tasks/conditional-checking/conditional';
import { getConditionalStyle } from '@/components/tasks/conditionalformatting/conditional-formatting';
import { AppPattern, gradientMap } from '@/data/Branding';
import { convertUUIDtoNumber } from '@/data/helpers/InitialsHelper';
import { compare } from '@/data/helpers/SearchHelper';
import { Attribute, AttributeType, TaskAttributeOptionType } from '@/data/tasks/Attribute';
import { MiniApp } from '@/data/tasks/MiniApp';
import { Task } from '@/data/tasks/Task';
import { TasksTable } from '@/data/tasks/TasksTable';
import { MiniAppView } from '@/data/tasks/TaskView';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';

import { AttributeStyle } from '../tasks/AttributeStyle';
import { DeployedAppWithRelease } from '../version/DeployedApp';

export const TITLE_ATTRIBUTE: string = 'Title';
export const TITLE_PLACEHOLDER: string = 'Unnamed task';
export const TASKS_DEFAULT_TABLE: string = '__Main';
export const TASKS_DEFAULT_TABLE_DISPLAY_NAME: string = 'Default';
export const TASKS_MINI_APP_NAME: string = '__Tasks';
export const RELATIONSHIP_ATTRIBUTE_TYPES: AttributeType[] = [
  AttributeType.USER, AttributeType.TRACK, AttributeType.RECORD, AttributeType.CONTENT, AttributeType.ARTICLE
];
export const NON_FORMATTABLE_ATTRIBUTES: AttributeType[] = [
  AttributeType.CHECKBOX, AttributeType.IMAGE
];
export const USER_ATTRIBUTE_TYPES: AttributeType[] = [
  AttributeType.USER, AttributeType.CREATED_BY, AttributeType.LAST_UPDATED_BY
];
export const TASK_HIGHLIGHT_TIME: number = 3000;
export const DEFAULT_APP_ICON: string = 'app-icons/general/application';

export function linkedTaskDisplayText(linkedTask: Task, attribute: Attribute, tables: TasksTable[]): string {
  let displayAttribute: string | undefined;
  if (attribute.options) {
    displayAttribute = attribute.options[TaskAttributeOptionType.DISPLAY_ATTRIBUTE];
  }
  if (!displayAttribute) {
    displayAttribute = TITLE_ATTRIBUTE;
  }
  let taskDisplay = linkedTask.properties[displayAttribute];
  if (!taskDisplay) {
    const table = tables.find((table) => table.id === linkedTask.tableId);
    if (table) {
      for (const attr of table.schema) {
        if (attr.type === AttributeType.TEXT) {
          taskDisplay = linkedTask.properties[attr.name];
          break;
        }
      }
    }
  }

  return taskDisplay ?? TITLE_PLACEHOLDER;
}

export function createTaskAutocompleteItem(task: Task, tables: TasksTable[], attribute: Attribute):
 { itemText: string, displayText: string, element: Task } {
  const taskId = task.id;
  const linkDisplayText = linkedTaskDisplayText(task, attribute, tables);

  return {
    itemText: taskId,
    displayText: linkDisplayText,
    element: task
  };
}

export function isStretchedOverlayAttribute(attributeType: AttributeType): boolean {
  return [AttributeType.LONG_TEXT, AttributeType.TRACK, AttributeType.RECORD, AttributeType.USER, AttributeType.CONTENT,
    AttributeType.ARTICLE].includes(attributeType);
}

export function compareViewsForSort(a: MiniAppView, b: MiniAppView): number {
  if (a.name === b.name) {
    return compare(a.id, b.id);
  }
  return compare(a.name, b.name);
}

export function getTableDisplayName(table: TasksTable): string {
  return table.name === TASKS_DEFAULT_TABLE ? TASKS_DEFAULT_TABLE_DISPLAY_NAME : table.name;
}

export function compareTaskProperties(valueA: string | number | undefined,
  valueB: string | number | undefined): number {
  valueA = typeof valueA === 'string' ? valueA.toLowerCase() : valueA;
  valueB = typeof valueB === 'string' ? valueB.toLowerCase() : valueB;
  if (valueA === '' || valueA === undefined) {
    return 1;
  } else if (valueB === '' || valueB === undefined) {
    return -1;
  } else {
    if (!isNaN(+valueA - +valueB)) {
      return +valueA - +valueB;
    } else {
      if (!isNaN(+valueA)) {
        return -1;
      } else if (!isNaN(+valueB)) {
        return 1;
      } else {
        return (valueA > valueB) ? 1 : (valueA < valueB) ? -1 : 0;
      }
    }
  }
}

export function patternNameForApp(miniApp?: MiniApp | DeployedAppWithRelease | null): string {
  let gradientName: string | undefined =
   (miniApp as MiniApp)?.pattern || (miniApp as DeployedAppWithRelease)?.currentRelease?.pattern;
  if (!gradientName) {
    // If the app doesn't have a colour set, use one based on its ID
    const gradientNames: string[] = Object.keys(gradientMap);
    let colourIndex: number = 0;
    if (!miniApp) {
      colourIndex = Math.min(gradientNames.length - 1, Math.floor(Math.random() * gradientNames.length));
    } else {
      colourIndex = convertUUIDtoNumber(((miniApp as MiniApp).id || (miniApp as DeployedAppWithRelease).appId) ??
                                        ((miniApp as MiniApp).name || (miniApp as DeployedAppWithRelease).appName) ??
                                        'default') % gradientNames.length;
    }
    gradientName = gradientNames[colourIndex];
  }
  return gradientName;
}

export function patternForApp(miniApp?: MiniApp | DeployedAppWithRelease | null): AppPattern {
  return gradientMap[patternNameForApp(miniApp)];
}

export function getSolidAppIconName(iconName?: string): string {
  let toReturn: string = iconName ?? DEFAULT_APP_ICON;
  if (iconName?.endsWith('-light') || iconName?.endsWith('-compact')) {
    toReturn = toReturn.substring(0, toReturn.lastIndexOf('-'));
  }
  return toReturn + '-solid';
}

export function getCollaborateApp(): MiniApp {
  return {
    id: DEFAULT_COLLABORATION_APP_ID,
    name: 'Collaborate',
    description: 'Default app for general collaboration',
    trackId: '',
    template: false,
    fromTemplateId: '',
    showOnNavigationBar: false,
    appIcon: 'app-icons/people/users-groups',
    webhooks: [],
    permissions: [],
    workspaceLabels: [],
    environmentVariables: [],
  };
}

// The applicable styles consist of the task's styles, merged with (and overridden by) any conditional styles.
export function getApplicableStyles(task: Task, attributes: Attribute[], table: TasksTable):
  Record<string, AttributeStyle> {
  const applicableStyles: Record<string, AttributeStyle> = {};
  const taskStyles = task.styles || {};
  attributes.forEach(attr => {
    applicableStyles[attr.name] = {
      ...taskStyles[attr.name],
      ...getConditionalStyles(task, attributes, table)[attr.name],
    };
  });
  return applicableStyles;
}

export function getConditionalStyles(task: Task, attributes: Attribute[], table: TasksTable):
  Record<string, AttributeStyle> {
  const conditionalStyles: Record<string, AttributeStyle> = {};
  attributes.forEach(attribute => {
    if (attribute.conditionalFormatting) {
      // Last updated and created values are stored as their own keys on the properties record
      const attributeValue = attribute.type === AttributeType.LAST_UPDATED
        ? task.properties.__UPDATED
        : attribute.type === AttributeType.CREATED_DATE
          ? task.properties.__CREATED
          : attribute.type === AttributeType.RULESET
            ? task.properties[attribute.name]
            : task.properties[attribute.name];
      const formulaArgs: FormulaConditionArgs = {
        schema: table.schema,
        recordProperties: task.properties,
      };
      const attributeStyle = getConditionalStyle(attribute.conditionalFormatting, attributeValue, formulaArgs);
      conditionalStyles[attribute.name] = attributeStyle;
    }
  });
  return conditionalStyles;
}
