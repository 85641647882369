import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';
import { Location } from 'vue-router';

import BrowserDetection from '@/data/BrowserDetection';
import { SearchLocation } from '@/data/datatypes/SemanticSearchQuery';
import { CalendarFilter } from '@/data/helpers/CalendarHelper';
import { FlowConfig } from '@/data/tasks/FlowConfig';
import RouteNames from '@/router/RouteNames';
import { usePushNotificationStore } from '@/stores/PushNotification';
import { DEFAULT_COLLABORATION_APP_ID } from '@/stores/Tasks';
import { FilteredContactCounts, MeetingContentTab } from '@/stores/UIState.types';

import pinia from '.';
import { useRouteStore } from './Route';
import { useTracksStore } from './Tracks';

export function getRouteForTrack(trackId: string, owningMiniAppId: string | undefined): Location {
  const tracksStore = useTracksStore();
  const track = tracksStore.tracks[trackId];
  if (track?.environmentId && track.owningMiniAppId) {
    return {
      name: RouteNames.APP_ENVIRONMENT_TRACK_VIEW_NAME,
      params: {
        trackId: trackId,
        tenantAppId: track.owningMiniAppId,
        environmentId: track.environmentId
      }
    };
  }
  const tenantAppId = owningMiniAppId || DEFAULT_COLLABORATION_APP_ID;
  return { name: RouteNames.TRACK_DEFAULT_ROUTE_NAME, params: { trackId, tenantAppId } };
}

export const useUIStateStore = defineStore('UIState', () => {
  const ADDITIONAL_CONTENT_VISIBLE_KEY = 'us_acv';
  const ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY = 'us_amcv';
  const ADDITIONAL_MEETING_CONTENT_TAB_KEY = 'us_amct';
  const LAST_VIEWED_WORKSPACE_KEY = 'us_lvw';
  const LAST_VIEWED_CHAT_KEY = 'us_lvc';
  const CHAT_FORMATTING_EXPANDED_KEY = 'us_cfe';
  const CALENDAR_FILTER_KEY = 'us_cal';
  const ENABLED_APPS_FOR_CALENDARS = 'us_eafc';

  const showAdditionalContent: Ref<boolean> = ref(true);
  const flow: Ref<FlowConfig | null> = ref(null);
  const appGuestView: Ref<boolean> = ref(false);
  const showAdditionalMeetingContent: Ref<boolean> = ref(false);
  const selectedMeetingContentTab: Ref<MeetingContentTab> = ref(MeetingContentTab.PARTICIPANTS);
  const privacyMode: Ref<boolean> = ref(false);
  const mobileSideMenuOpen: Ref<boolean> = ref(false);
  const expandedTrackLabels: Ref<Record<string, Record<string, boolean>>> = ref({});
  const expandedTracks: Ref<Record<string, boolean>> = ref({});
  const lastViewedWorkspaceId: Ref<string | null | undefined> = ref(undefined);
  const lastViewedChatId: Ref<string | null | undefined> = ref(undefined);
  const chatFormattingExpanded: Ref<boolean> = ref(true);
  const calendarFilter: Ref<string> = ref(CalendarFilter.MONTH);
  const workspaceFilter: Ref<boolean> = ref(false);
  const searchLocation: Ref<SearchLocation> = ref(SearchLocation.ALL);
  const contactsSearch: Ref<string> = ref('');
  const showEmbedded: Ref<boolean> = ref(true);
  const preventOrientationBlock: Ref<boolean> = ref(false);
  const filteredContactCounts: Ref<FilteredContactCounts> = ref({
    all: 0,
    personal: 0,
    tenant: 0,
    externalTenant: 0,
  });

  const tabVisible: Ref<boolean> = ref(true);
  const enabledAppsForCalendar: Ref<string[] | null> = ref(null);

  const activePopupMenuId: Ref<string> = ref('');
  const popupMenuIsVisible: Ref<boolean> = ref(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allCodeEditorSchemas: Ref<{ schema: any; uri: string; fileMatch?: string[] }[]> = ref([]);

  const initialIDBLoadComplete: Ref<boolean> = ref(false);

  const sideMenuOpen: ComputedRef<boolean> = computed(() => {
    return mobileSideMenuOpen.value;
  });

  const calendarFilterValue: ComputedRef<string> = computed(() => {
    return calendarFilter.value;
  });

  const searchMenuOverlayLocation: ComputedRef<SearchLocation> = computed(() => {
    return searchLocation.value;
  });

  const workspaceFilterValue: ComputedRef<boolean> = computed(() => {
    return workspaceFilter.value;
  });

  const currentUiFlowMode: ComputedRef<string> = computed(() => {
    return flow.value?.mode ?? '';
  });

  const hideHeaderBar: ComputedRef<boolean> = computed(() => {
    const routeStore = useRouteStore(pinia);
    if (routeStore.route?.name === RouteNames.APP_ENVIRONMENT_TRACK_VIEW_FULL_SCREEN_NAME) {
      return true;
    }
    if (currentUiFlowMode.value === 'STANDALONE_OPTIONAL') {
      return !showEmbedded.value;
    }
    return appGuestView.value || ['STANDALONE', 'ISOLATED'].includes(currentUiFlowMode.value);
  });

  const hideSupplementaryMenu: ComputedRef<boolean> = computed(() => {
    if (flow.value?.mode === 'STANDALONE_OPTIONAL') {
      return !showEmbedded.value;
    }
    return appGuestView.value || ['STANDALONE', 'ISOLATED'].includes(currentUiFlowMode.value);
  });

  const hideTrackHeader: ComputedRef<boolean> = computed(() => {
    return appGuestView.value || ['STANDALONE', 'ISOLATED', 'STANDALONE_OPTIONAL'].includes(currentUiFlowMode.value);
  });

  function setCurrentFlow(flowToSet?: FlowConfig | null): void {
    flow.value = flowToSet ?? null;
  }

  function setAppGuestView(isAppGuestView: boolean): void {
    appGuestView.value = isAppGuestView;
  }

  function setMobileSideMenuOpen(value: boolean): void {
    mobileSideMenuOpen.value = value;
  }

  function toggleMobileSideMenuOpen(): void {
    mobileSideMenuOpen.value = !mobileSideMenuOpen.value;
  }

  function setShowEmbedded(newVal: boolean): void {
    showEmbedded.value = newVal;
  }

  function setAdditionalContentVisible(newVal: boolean): void {
    showAdditionalContent.value = newVal;
    localStorage.setItem(ADDITIONAL_CONTENT_VISIBLE_KEY, JSON.stringify(newVal));
  }

  function setCalendarFilter(newVal: string): void {
    calendarFilter.value = newVal;
    localStorage.setItem(CALENDAR_FILTER_KEY, JSON.stringify(newVal));
  }

  function setSearchMenuOverlayLocation(location: SearchLocation): void {
    searchLocation.value = location;
  }

  function setWorkspaceFilter(newVal: boolean): void {
    workspaceFilter.value = newVal;
  }

  function setAdditionalMeetingContentVisible(newVal: boolean): void {
    showAdditionalMeetingContent.value = newVal;
    localStorage.setItem(ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY, JSON.stringify(newVal));
  }

  function setSelectedMeetingContentTab(newVal: MeetingContentTab): void {
    selectedMeetingContentTab.value = newVal;
    localStorage.setItem(ADDITIONAL_MEETING_CONTENT_TAB_KEY, JSON.stringify(newVal));
  }

  function setPrivacyMode(newVal: boolean): void {
    privacyMode.value = newVal;
  }

  function toggleExpandedTrackLabel(details: { labelId: string; labelValue: string }): void {
    let labelValues = expandedTrackLabels.value[details.labelId];
    if (!labelValues) {
      labelValues = {};
      Vue.set(expandedTrackLabels.value, details.labelId, labelValues);
    }
    // TODO: Do we want to persist this in local storage?
    Vue.set(expandedTrackLabels.value[details.labelId], details.labelValue, !labelValues[details.labelValue]);
  }

  function toggleExpandedTrack(trackId: string): void {
    const currentlyExpanded = expandedTracks.value[trackId];
    Vue.set(expandedTracks.value, trackId, !currentlyExpanded);
  }

  function setLastWorkspaceId(newVal: string): void {
    lastViewedWorkspaceId.value = newVal;
    localStorage.setItem(LAST_VIEWED_WORKSPACE_KEY, JSON.stringify(newVal));
  }

  function setLastChatId(newVal: string): void {
    lastViewedChatId.value = newVal;
    localStorage.setItem(LAST_VIEWED_CHAT_KEY, JSON.stringify(newVal));
  }

  function setChatFormattingExpanded(newVal: boolean): void {
    chatFormattingExpanded.value = newVal;
    localStorage.setItem(CHAT_FORMATTING_EXPANDED_KEY, JSON.stringify(newVal));
  }

  function setContactsSearch(newVal: string): void {
    contactsSearch.value = newVal;
  }

  function setFilteredContactCounts(newVal: FilteredContactCounts): void {
    filteredContactCounts.value = newVal;
  }

  function setPreventOrientationBlock(value: boolean): void {
    preventOrientationBlock.value = value;
  }

  function setTabVisible(newVal: boolean): void {
    tabVisible.value = newVal;
  }

  function toggleAppCalendar(appId: string): void {
    if (!enabledAppsForCalendar.value) {
      enabledAppsForCalendar.value = [];
    }
    const index = enabledAppsForCalendar.value?.findIndex(id => id === appId);
    if (index > -1) {
      enabledAppsForCalendar.value.splice(index, 1);
    } else {
      enabledAppsForCalendar.value.push(appId);
    }
    localStorage.setItem(ENABLED_APPS_FOR_CALENDARS, JSON.stringify(enabledAppsForCalendar.value));
  }

  function setInitialIDBLoadComplete(isComplete: boolean): void {
    initialIDBLoadComplete.value = isComplete;
  }

  async function loadSettings(): Promise<void> {
    try {
      const pushNotificationStore = usePushNotificationStore();
      pushNotificationStore.loadSettings();
    } catch (error) {
      // Catch so it doesn't risk the rest of the UI load (shouldn't because it's async, but better not risk it)
    }

    showAdditionalContent.value = getLocalStorageBoolean(ADDITIONAL_CONTENT_VISIBLE_KEY);
    // On mobile we always default to the additional content panel being closed on start.
    showAdditionalMeetingContent.value = !BrowserDetection.isMobile() &&
      getLocalStorageBoolean(ADDITIONAL_MEETING_CONTENT_VISIBLE_KEY, false);
    selectedMeetingContentTab.value =
      getLocalStorageString(ADDITIONAL_MEETING_CONTENT_TAB_KEY) as MeetingContentTab ?? MeetingContentTab.CHAT;
    lastViewedWorkspaceId.value = getLocalStorageString(LAST_VIEWED_WORKSPACE_KEY) ?? null;
    lastViewedChatId.value = getLocalStorageString(LAST_VIEWED_CHAT_KEY) ?? null;
    chatFormattingExpanded.value = getLocalStorageBoolean(CHAT_FORMATTING_EXPANDED_KEY);
    calendarFilter.value = getLocalStorageString(CALENDAR_FILTER_KEY) ?? CalendarFilter.MONTH;
    enabledAppsForCalendar.value = getLocalStorageStringArray(ENABLED_APPS_FOR_CALENDARS) ?? null;
  }

  function getLocalStorageString(key: string): string | null {
    const valueStr: string | null = localStorage.getItem(key);
    let value: string | null = null;
    if (valueStr !== null && valueStr !== undefined && valueStr !== '') {
      value = JSON.parse(valueStr);
    }
    return value;
  }

  function getLocalStorageStringArray(key: string): string[] | null {
    const valueStr: string | null = localStorage.getItem(key);
    let value: string[] | null = null;
    if (valueStr) {
      value = JSON.parse(valueStr);
    }
    return value;
  }

  function getLocalStorageBoolean(key: string, defaultValue: boolean = true): boolean {
    const valueStr: string | null = localStorage.getItem(key);
    let value: boolean = defaultValue;
    if (valueStr !== null && valueStr !== undefined && valueStr !== '') {
      value = JSON.parse(valueStr);
    }
    return value;
  }

  return {
    showAdditionalContent,
    showAdditionalMeetingContent,
    selectedMeetingContentTab,
    privacyMode,
    expandedTrackLabels,
    expandedTracks,
    lastViewedWorkspaceId,
    chatFormattingExpanded,
    contactsSearch,
    showEmbedded,
    preventOrientationBlock,
    filteredContactCounts,
    tabVisible,
    enabledAppsForCalendar,
    sideMenuOpen,
    calendarFilterValue,
    searchMenuOverlayLocation,
    workspaceFilterValue,
    hideHeaderBar,
    hideSupplementaryMenu,
    hideTrackHeader,
    activePopupMenuId,
    popupMenuIsVisible,
    allCodeEditorSchemas,
    initialIDBLoadComplete,
    setCurrentFlow,
    setMobileSideMenuOpen,
    toggleMobileSideMenuOpen,
    setShowEmbedded,
    setAdditionalContentVisible,
    setCalendarFilter,
    setSearchMenuOverlayLocation,
    setWorkspaceFilter,
    setAdditionalMeetingContentVisible,
    setSelectedMeetingContentTab,
    setPrivacyMode,
    toggleExpandedTrackLabel,
    toggleExpandedTrack,
    setLastWorkspaceId,
    setLastChatId,
    setChatFormattingExpanded,
    setContactsSearch,
    setFilteredContactCounts,
    setAppGuestView,
    setPreventOrientationBlock,
    setTabVisible,
    toggleAppCalendar,
    loadSettings,
    setInitialIDBLoadComplete
  };
});
