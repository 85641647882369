import log from 'loglevel';
import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import {
  DEVELOPMENT_ENVIRONMENT_ID,
  Environment,
  EnvironmentPipelineStage,
  UnsavedEnvironment
} from '@/data/release/Environment';
import DataWorker from '@/data/storage/DataWorker';

import pinia from '..';
import { useRouteStore } from '../Route';
import { useTracksStore } from '../Tracks';

export const useEnvironmentsStore = defineStore('Environments', () => {
  const environments: Ref<Record<string, Environment[]>> = ref({});

  const activeEnvironmentId: ComputedRef<string> = computed(() => {
    const routeStore = useRouteStore(pinia);
    const environmentIdFromRoute = routeStore.route?.params.environmentId;
    if (environmentIdFromRoute) {
      return environmentIdFromRoute;
    }
    const tracksStore = useTracksStore(pinia);
    const activeTrack = tracksStore.activeTrack;
    return activeTrack?.environmentId ?? DEVELOPMENT_ENVIRONMENT_ID;
  });

  function sortedEnvironmentsForApp(appId: string): Environment[] {
    // Sort environments in to their pipeline order
    const sortedEnvs: Environment[] = [];
    const envs = environments.value[appId]?.slice() || [];
    while (envs.length > 0) {
      const lastLen = envs.length;
      for (let i = envs.length - 1; i >= 0; i--) {
        const env = envs[i];
        if (sortedEnvs.length === 0) {
          if (!env.precedingEnvironmentId) {
            sortedEnvs.push(env);
            envs.splice(i, 1);
          }
        } else {
          const lastEnv = sortedEnvs[sortedEnvs.length - 1];
          if (env.precedingEnvironmentId === lastEnv.id) {
            sortedEnvs.push(env);
            envs.splice(i, 1);
          }
        }
      }
      if (envs.length === lastLen) {
        // Failed to process any environments in this loop, break with an error
        log.error(`Failed to sort the environments for app ${appId} pipeline invalid`);
        return environments.value[appId]?.slice() || [];
      }
    }
    return sortedEnvs;
  }

  function setEnvironments(details: { environments: Environment[], fullRefresh: boolean }): void {
    log.debug(`Setting ${details.environments.length} environments`);
    const updatesByApp: Record<string, Environment> = {};
    details.environments.forEach(environment => {
      updatesByApp[environment.appId] = environment;
      const appEnvs: Environment[] = environments.value[environment.appId] || [];
      const envIndex = appEnvs.findIndex(e => e.id === environment.id);
      if (envIndex >= 0) {
        appEnvs.splice(envIndex, 1, environment);
      } else {
        appEnvs.push(environment);
      }

      if (appEnvs.length === 1) {
        Vue.set(environments.value, environment.appId, appEnvs);
      }
    });

    // If it's a full refresh, then delete any records that aren't in the updated object
    if (details.fullRefresh) {
      const removedAppIds: string[] = Object.keys(environments.value).filter((appId: string) => {
        return !updatesByApp[appId];
      });
      for (const removedId of removedAppIds) {
        if (environments.value[removedId]) {
          log.debug(`Removing environments for app [${removedId}]`);
          Vue.delete(environments.value, removedId);
        }
      }
    }

    log.debug(`After set ${Object.keys(environments.value).length} apps have environments`);
  }

  const pipelines: ComputedRef<Record<string, EnvironmentPipelineStage[]>> = computed(() => {
    const result: Record<string, EnvironmentPipelineStage[]> = {};
    Object.keys(environments.value).forEach(appId => {
      const appEnvironments = environments.value[appId];
      const environmentsByPrevious: Record<string, Environment[]> = {};
      appEnvironments.forEach(environment => {
        const preceding = environment.precedingEnvironmentId || 'start';
        if (!environmentsByPrevious[preceding]) {
          environmentsByPrevious[preceding] = [];
        }
        environmentsByPrevious[preceding].push(environment);
      });
      const appPipelines: EnvironmentPipelineStage[] = [];
      environmentsByPrevious.start.forEach(environment => {
        const pipeline: EnvironmentPipelineStage = {
          environment: environment,
          subsequentStages: []
        };
        appPipelines.push(pipeline);
        populatePipeline(pipeline, environmentsByPrevious);
      });
      result[appId] = appPipelines;
    });
    return result;
  });

  async function createEnvironment(environment: UnsavedEnvironment): Promise<Environment> {
    return await DataWorker.instance().dispatch('Environments/createEnvironment', environment);
  }

  async function updateEnvironment(environment: Omit<Environment, 'connections'>): Promise<Environment> {
    return await DataWorker.instance().dispatch('Environments/updateEnvironment', environment);
  }

  async function deployRelease(environmentId: string, releaseId: string): Promise<Environment> {
    const updatedEnvironment =
      await DataWorker.instance().dispatch('Environments/deployRelease', environmentId, releaseId);
    const appEnvironments = environments.value[updatedEnvironment.appId] || [];
    const index = appEnvironments.findIndex(e => e.id === updatedEnvironment.id);
    if (index >= 0) {
      appEnvironments.splice(index, 1, updatedEnvironment);
    }
    return updatedEnvironment;
  }

  /**
   * Prompts the server to update the workflow connections for an environment.
   * This is triggered from here because connections are created on the AP server
   * in an iFrame, so the client gets a hint when it occurs that the server does not
   * @param environmentId
   */
  function updateEnvironmentConnections(environmentId: string): void {
    DataWorker.instance().dispatch('Environments/updateEnvironmentConnections', environmentId);
  }

  return {
    environments,
    sortedEnvironmentsForApp,
    setEnvironments,
    createEnvironment,
    updateEnvironment,
    updateEnvironmentConnections,
    deployRelease,
    pipelines,
    activeEnvironmentId,
  };
});

function populatePipeline(pipeline: EnvironmentPipelineStage, environmentsByPrevious: Record<string, Environment[]>) {
  const subsequentEnvironments = environmentsByPrevious[pipeline.environment.id] || [];
  subsequentEnvironments.forEach(subsequentEnvironment => {
    const subsequentStage = {
      environment: subsequentEnvironment,
      subsequentStages: []
    };
    pipeline.subsequentStages.push(subsequentStage);
    populatePipeline(subsequentStage, environmentsByPrevious);
  });
}
