/**
 * Returns true if the argument is null or undefined
 * otherwise returns false
 * @param ref
 * @returns
 */
export function notNullish<T>(ref: T | null | undefined): ref is T {
  return ref !== null && ref !== undefined;
}

export function isNullish(ref: unknown): ref is undefined | null {
  return ref === null || ref === undefined;
}
