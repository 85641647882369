import { defineStore } from 'pinia';
import Vue, { computed, ComputedRef, Ref, ref } from 'vue';

import {
  AppAuthResult,
  cloneDataSourceResult, DataSource, DataSourceConfig, DataSourceConfigParam,
  DataSourceImportRequest,
  DataSourceImportResponse,
  DataSourceResult, RestDataSourceConfig
} from '@/data/config/DataSource';
import { ExternalApiRequest, ExternalApiResponse } from '@/data/datatypes/externalApis/ExternalAPIRequest';
import Tenant from '@/data/datatypes/Tenant';
import { Track } from '@/data/datatypes/Track';
import Label from '@/data/datatypes/trackCustomisations/Label';
import TrackLabel from '@/data/datatypes/TrackLabel';
import { integratorsLog } from '@/data/log/IntegratorsLog';
import DataWorker from '@/data/storage/DataWorker';
import {
  removeAppAuthTokenFromLocalStorage,
  storeAppAuthTokenInLocalStorage
} from '@/data/tasks/customviews/AppAuthHelper';
import { MiniApp } from '@/data/tasks/MiniApp';
import { cloneTask, Task } from '@/data/tasks/Task';
import { DataSourceId, DataSourceParameters, DataSourceState, TrackId } from '@/stores/DataSources.types';
import pinia from '@/stores/index';
import { asRecord, patchArray, patchObject } from '@/stores/StoreHelper';
import { useTasksStore } from '@/stores/Tasks';
import { useTenantsStore } from '@/stores/Tenants';
import { useTracksStore } from '@/stores/Tracks';
import { useUserStore } from '@/stores/User';

import { useDeployedAppsStore } from './release/DeployedApps';

interface ResolveAndReject {
  resolve: () => void;
  reject: (error: Error) => void;
}

export const useDataSourcesStore = defineStore('DataSources', () => {
  const userStore = useUserStore(pinia);
  const DATASOURCE_DEBOUNCE_TIMEOUT = 100;

  // data sources defined at the tenant level:
  const tenantDataSources: Ref<DataSource[]> = ref([]);
  // data sources defined for a particular app:
  const appDataSourcesByAppId: Ref<Record<string, DataSource[]>> = ref({});
  // Parameters that must be redacted from memory for privacy reasons. The string[] here is
  // an array of parameter names that should be redacted.
  const parametersToRedact: Ref<Record<DataSourceId, Record<TrackId, string[]>>> = ref({});
  // The last parameters searched on any given data source and track combination
  const lastParameters: Ref<Record<DataSourceId, Record<TrackId, DataSourceParameters>>> = ref({});
  // the data read for a source in the context of a workspace
  const data: Ref<Record<DataSourceId, Record<TrackId, DataSourceResult>>> = ref({});
  // the data read for a source in the context of a workspace
  const dataSourceState: Ref<Record<DataSourceId, Record<TrackId, DataSourceState>>> = ref({});
  // the data source parameters that have been set on a given track - this is either keyed
  // on data source ID (when we're using the legacy GWT mode) or on data set ID in the
  // custom builder
  const dataSourceParameters: Ref<Record<string, Record<TrackId, DataSourceParameters>>> = ref({});
  // debounce timers to prevent data sources being hit quickly in succession
  const dataSourcesDebounceTimers: Ref<Record<string, ReturnType<typeof setTimeout>>> = ref({});
  const dataSourcesDebounceCallbacks: Ref<Record<string, ResolveAndReject[]>> = ref({});

  // TODO: In most places where this is used, we could just reference tenantDataSources or appDataSourcesByAppId.
  const dataSources: ComputedRef<DataSource[]> = computed(() => {
    const allAppSources = Object.values(appDataSourcesByAppId.value).flat();
    return tenantDataSources.value.concat(allAppSources);
  });

  const dataSourcesById: ComputedRef<Record<string, DataSource>> = computed(() => {
    const trackStore = useTracksStore();
    const activeTrack = trackStore.activeTrack;
    if (activeTrack) {
      if (activeTrack.owningMiniAppId && activeTrack.environmentId) {
        const deployedAppStore = useDeployedAppsStore();
        const environmentApp = deployedAppStore.availableDeployedApps.find(app =>
          app.appId === activeTrack.owningMiniAppId &&
           app.environmentId === activeTrack.environmentId
        );
        // The app is operating in the context of an app environment
        // so return the set of datasources for that environment
        const appSourcesById: Record<string, DataSource> = {};
        if (environmentApp) {
          environmentApp.currentRelease.dataSources.forEach(ds => {
            if (ds.id) {
              appSourcesById[ds.id] = ds;
            }
          });
        }
        return appSourcesById;
      }
    }
    return Object.fromEntries(dataSources.value.map((dataSource: DataSource) => [dataSource.id, dataSource]));
  });

  function setDataSourceState(details: { id: string, trackId: string, loading: boolean }): void {
    let byId: Record<string, DataSourceState> = dataSourceState.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(dataSourceState.value, details.id, byId);
    }

    let byTrackId: DataSourceState = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {
        loading: details.loading
      };
      Vue.set(byId, details.trackId, byTrackId);
    }

    byTrackId.loading = details.loading;
  }

  function setData(details: { id: string, trackId: string, result: DataSourceResult }): void {
    let byId: Record<string, DataSourceResult> = data.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(data.value, details.id, byId);
    }

    let byTrackId: DataSourceResult = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {
        records: [],
        success: true,
        totalPageCount: 0,
        pageSize: 0,
        page: 0,
        pagedResults: false,
        date: 0,
        parameters: {},
      };
      Vue.set(byId, details.trackId, byTrackId);
    }

    if (!byTrackId.records) {
      byTrackId.records = [];
    }
    // N.B. in an error response the records property doesn't exist, which means we'll 'remember' any previous results.
    // This is probably not by design, but changing it is risky as a lot of operations flow through here.
    // See https://gitlab.com/cafex-communications/fender/fender/-/issues/11103 for more details
    if (details.result.records) {
      patchArray(byTrackId.records, details.result.records, true);
    }

    byTrackId.errorMessage = details.result.errorMessage;
    byTrackId.success = details.result.success;
    byTrackId.totalPageCount = details.result.totalPageCount;
    byTrackId.pageSize = details.result.pageSize;
    byTrackId.page = details.result.page;
    byTrackId.date = details.result.date;

    // remove any parameters that have been marked for redaction
    const resultParams = details.result.parameters;
    const redactedParams = parametersToRedact.value[details.id]?.[details.trackId];
    if (redactedParams) {
      for (const param of redactedParams) {
        delete resultParams[param];
      }
    }
    byTrackId.parameters = resultParams;
  }

  function clearData(details: { id: string, trackId: string }): void {
    const resultsById: Record<string, DataSourceResult> | undefined = data.value[details.id];
    if (resultsById) {
      Vue.delete(resultsById, details.trackId);
    }
    const lastParametersById: Record<TrackId, DataSourceParameters> | undefined = lastParameters.value[details.id];
    if (lastParametersById) {
      Vue.delete(lastParametersById, details.trackId);
    }
  }

  function setTenantSources(sources: DataSource[]): void {
    tenantDataSources.value = sources;
  }

  function setAppSources(miniAppId: string, sources: DataSource[]): void {
    Vue.set(appDataSourcesByAppId.value, miniAppId, sources);
  }

  function clearAllAppSources(): void {
    appDataSourcesByAppId.value = {};
  }

  function clearAppSources(miniAppId: string): void {
    Vue.delete(appDataSourcesByAppId.value, miniAppId);
  }

  function setNewDataSources(sources: DataSource[], miniAppId?: string): void {
    if (!miniAppId) {
      // The server returned tenant-level data sources.
      setTenantSources(sources);
    } else if (miniAppId === 'all') {
      // The server returned data sources for all apps in the tenant.
      const sourcesByAppId: Record<string, DataSource[]> = {};
      for (const dataSource of sources) {
        if (dataSource.miniAppId) {
          if (!sourcesByAppId[dataSource.miniAppId]) {
            sourcesByAppId[dataSource.miniAppId] = [];
          }
          sourcesByAppId[dataSource.miniAppId].push(dataSource);
        }
      }
      clearAllAppSources();
      for (const [miniAppId, dataSourceList] of Object.entries(sourcesByAppId)) {
        setAppSources(miniAppId, dataSourceList);
      }
    } else {
      // The app ID is either for a normal top-level app, in which case the server returned the data sources for that
      // app, or it's a deprecated WS-level app copy, in which case the server returned both tenant-level data sources
      // and any added directly to the app copy.
      const appSources: DataSource[] = [];
      const tenantSources: DataSource[] = [];
      for (const dataSource of sources) {
        if (dataSource.miniAppId) {
          appSources.push(dataSource);
        } else {
          tenantSources.push(dataSource);
        }
      }
      if (appSources.length) {
        setAppSources(miniAppId, appSources);
      } else {
        clearAppSources(miniAppId);
      }
      // If miniAppId is for a top-level app then the following line will clear out tenant-level data sources for
      // no reason, but that shouldn't matter because they get refreshed every time they are needed anyway.
      setTenantSources(tenantSources);
    }
  }

  function addDataSourceRecord(details: { id: string, trackId: string, result: DataSourceResult}): void {
    let byId: Record<string, DataSourceResult> = data.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(data.value, details.id, byId);
    }

    let byTrackId: DataSourceResult = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {
        records: [],
        success: true,
        totalPageCount: 0,
        pageSize: 0,
        page: 0,
        pagedResults: false,
        date: 0,
        parameters: {},
      };
      Vue.set(byId, details.trackId, byTrackId);
    }

    integratorsLog.debug(`[DataSource] adding record to ${details.trackId} data source`);
    if (!byTrackId.records) {
      byTrackId.records = [];
    }
    if (details.result.records) {
      for (const task of details.result.records) {
        byTrackId.records.push(task);
      }
    }

    byTrackId.errorMessage = details.result.errorMessage;
    byTrackId.success = details.result.success;
    byTrackId.date = details.result.date;
    byTrackId.parameters = details.result.parameters;
  }

  function updateDataSourceRecord(details: { id: string, trackId: string, result: DataSourceResult }): void {
    let byId: Record<string, DataSourceResult> = data.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(data.value, details.id, byId);
    }

    let byTrackId: DataSourceResult = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {
        records: [],
        success: true,
        totalPageCount: 0,
        pageSize: 0,
        page: 0,
        pagedResults: false,
        date: 0,
        parameters: {},
      };
      Vue.set(byId, details.trackId, byTrackId);
    }

    if (!byTrackId.records) {
      byTrackId.records = [];
    }
    if (details.result.records) {
      for (const task of details.result.records) {
        const toUpdate: Task | undefined = byTrackId.records.find(entry => entry.id === task.id);
        if (toUpdate) {
          patchObject(asRecord(toUpdate), asRecord(task));
        } else {
          byTrackId.records.push(task);
        }
      }
    }

    byTrackId.errorMessage = details.result.errorMessage;
    byTrackId.success = details.result.success;
    byTrackId.date = details.result.date;
    byTrackId.parameters = details.result.parameters;
  }

  function deleteDataSourceRecord(details: { id: string, trackId: string, result: DataSourceResult}): void {
    let byId: Record<string, DataSourceResult> = data.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(data.value, details.id, byId);
    }

    let byTrackId: DataSourceResult = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {
        records: [],
        success: true,
        totalPageCount: 0,
        pageSize: 0,
        page: 0,
        pagedResults: false,
        date: 0,
        parameters: {},
      };
      Vue.set(byId, details.trackId, byTrackId);
    }

    if (!byTrackId.records) {
      byTrackId.records = [];
    }
    if (details.result.records) {
      for (const task of details.result.records) {
        const toRemove: number | undefined = byTrackId.records.findIndex(entry => entry.id === task.id);
        if (toRemove) {
          byTrackId.records.splice(toRemove, 1);
        }
      }
    }

    byTrackId.errorMessage = details.result.errorMessage;
    byTrackId.success = details.result.success;
    byTrackId.parameters = details.result.parameters;
  }

  async function refreshDataSourcesMetaData(miniAppId?: string, guestId?: string): Promise<void> {
    // The server needs to be able to differentiate between 'only sources defined directly against the tenant' and
    // out-of-date clients that are not sending the miniAppId and therefore need all sources for the tenant and its apps
    const miniAppIdForRequest: string = miniAppId ?? 'none';
    const sources: DataSource[] = await DataWorker.instance().dispatch('DataSources/getDataSources', 'me',
      miniAppIdForRequest, guestId);
    setNewDataSources(sources, miniAppId);
  }

  async function clearDataSourceParameters(details: { id: string, trackId: string }): Promise<void> {
    // the ID here can be a data source ID or a data set ID
    const byId: Record<string, DataSourceParameters> = dataSourceParameters.value[details.id];
    if (byId) {
      Vue.delete(byId, details.trackId);
    }
  }

  // mark the parameters for a given data source as needing to be redacted. Those marked parameters
  // will be cleared from memory as soon as possible after refreshing the relevant data source
  function updateRedactedParameters(
    details: { id: string, trackId: string, paramsToRedact: string[] }): void {
    const redactedParamsForId: Record<string, string[]> = parametersToRedact.value[details.id];
    if (!redactedParamsForId) {
      Vue.set(parametersToRedact.value, details.id, {});
    }
    Vue.set(parametersToRedact.value[details.id], details.trackId, details.paramsToRedact);

    redactLastParameters(details.id, details.trackId);
  }

  // immediately redact from 'lastParameters' any parameters marked by 'updateRedactedParameters'
  function redactLastParameters(id: string, trackId: string): void {
    const paramsToRedact = parametersToRedact.value[id]?.[trackId];
    if (!paramsToRedact) {
      return;
    }
    const lastParamsForId: Record<string, DataSourceParameters> = lastParameters.value[id];
    if (lastParamsForId && lastParamsForId[trackId]) {
      paramsToRedact.forEach(param => {
        Vue.delete(lastParamsForId[trackId], param);
      });
    }
  }

  // immediately redact from 'dataSourceParameters' any parameters marked by 'updateRedactedParameters'
  function redactDataSourceParameters(id: string, trackId: string): void {
    const paramsToRedact = parametersToRedact.value[id]?.[trackId];
    if (!paramsToRedact) {
      return;
    }
    const paramsForId: Record<string, DataSourceParameters> = dataSourceParameters.value[id];
    if (paramsForId && paramsForId[trackId]) {
      paramsToRedact.forEach(param => {
        Vue.delete(paramsForId[trackId], param);
      });
    }
  }

  async function updateDataSourceParameter(
    details: { id: string, trackId: string, parameter: string, parameterValue?: string }): Promise<void> {
    // the ID here can be a data source ID or a data set ID
    let byId: Record<string, DataSourceParameters> = dataSourceParameters.value[details.id];
    if (!byId) {
      byId = {};
      Vue.set(dataSourceParameters.value, details.id, byId);
    }

    let byTrackId: DataSourceParameters = byId[details.trackId];
    if (!byTrackId) {
      byTrackId = {};
      Vue.set(byId, details.trackId, byTrackId);
    }

    Vue.set(byTrackId, details.parameter, details.parameterValue);
  }

  async function intRefreshDataSource(details: { id: string, trackId: string, miniAppId: string, force: boolean,
    resultName: string | undefined }, parametersAndResultId: string, parameters: DataSourceParameters): Promise<void> {
    const dataSource: DataSource = dataSourcesById.value[details.id];
    const config: RestDataSourceConfig = JSON.parse(dataSource.config);
    const sourceUrl: string = config.url;
    if (!sourceUrl || sourceUrl.length === 0) {
      // in this case we can safely ignore the request since the data source isn't
      // designed to be read and it's not an active click/choice by the user
      // to get the data
      return;
    }

    // Only refresh if the method is a GET
    if (config.method && config.method !== 'GET') {
      return;
    }

    // If this isn't  the initial version (used in Galileo), then ignore the request
    if (dataSource.dataSourceVersion && dataSource.dataSourceVersion > 1) {
      return;
    }

    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(sourceUrl, dataSource, details.trackId, config, parameters);
    if (!inputs && !details.force) {
      return;
    }

    integratorsLog.debug(`[${dataSource.name}] Getting data for source: ${details.id}`);
    try {
      setDataSourceState({
        id: parametersAndResultId,
        trackId: details.trackId,
        loading: true,
      });

      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);

      const result: DataSourceResult = await DataWorker.instance().dispatch('DataSources/getRecords', 'me',
        details.id, details.trackId, details.miniAppId, inputs, guestId);

      setDataSourceState({
        id: parametersAndResultId,
        trackId: details.trackId,
        loading: false,
      });

      const copyOfParameters = { ...parameters };
      result.parameters = copyOfParameters;

      if (details.resultName) {
        result.name = details.resultName;
      }

      setData({
        id: parametersAndResultId,
        trackId: details.trackId,
        result: result,
      });

      redactDataSourceParameters(parametersAndResultId, details.trackId);
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error reading records from: ${details.id}`);
      throw e;
    }
  }

  function debounceRefreshDataSource(details: { id: string, trackId: string, miniAppId: string, force: boolean,
    resultName: string | undefined }, parametersAndResultId: string, parameters: DataSourceParameters): Promise<void> {
    // We are debouncing the refresh, but after it completes we want to resolve or reject all of the promises
    // that have built up during the debounce period.

    // Clear any refresh that is already scheduled:
    clearTimeout(dataSourcesDebounceTimers.value[parametersAndResultId]);

    // Schedule the refresh:
    dataSourcesDebounceTimers.value[parametersAndResultId] = setTimeout(async () => {
      let error: Error | undefined;
      try {
        await intRefreshDataSource(details, parametersAndResultId, parameters);
      } catch (e) {
        error = e as Error;
      }
      if (dataSourcesDebounceCallbacks.value[parametersAndResultId]) {
        // Resolve or reject all promises that are waiting for this refresh:
        for (const resolveAndReject of dataSourcesDebounceCallbacks.value[parametersAndResultId]) {
          if (error) {
            resolveAndReject.reject(error);
          } else {
            resolveAndReject.resolve();
          }
        }
      }
      dataSourcesDebounceCallbacks.value[parametersAndResultId] = [];
      delete dataSourcesDebounceTimers.value[parametersAndResultId];
    }, DATASOURCE_DEBOUNCE_TIMEOUT);

    return new Promise((resolve, reject) => {
      if (!dataSourcesDebounceCallbacks.value[parametersAndResultId]) {
        dataSourcesDebounceCallbacks.value[parametersAndResultId] = [];
      }
      // Add our callbacks to the list that will be called after the debounced call has eventually been executed:
      dataSourcesDebounceCallbacks.value[parametersAndResultId].push({ resolve, reject });
    });
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function refreshDataSourceForTrack(details: { id: string, trackId: string, miniAppId: string, force: boolean,
    dataSetId: string | undefined, resultName: string | undefined }): Promise<void> {
    const parametersAndResultId: string = details.dataSetId ?? details.id;
    if (!details.force) {
      // if we're not forcing a refresh and we already have data just leave it in place
      if (data.value[parametersAndResultId] && data.value[parametersAndResultId][details.trackId]) {
        return;
      }
    }

    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.id];
    // If there's no data source, or if it isn't the initial version (used in Galileo), then ignore the request
    if (!dataSource || (dataSource.dataSourceVersion ?? 1) > 1) {
      // data source information is probably not pulled yet - don't need to log this
      // since its just confusion for debugging
      return;
    }

    const lastParametersUsed = lastParameters.value[parametersAndResultId]?.[details.trackId];
    const parameters: DataSourceParameters = dataSourceParameters.value[parametersAndResultId]?.[details.trackId] ?? {};

    if (!recordEquals(parameters, lastParametersUsed) || details.force) {
      // store a copy of the parameters being used
      if (!lastParameters.value[parametersAndResultId]) {
        Vue.set(lastParameters.value, parametersAndResultId, {});
      }
      Vue.set(lastParameters.value[parametersAndResultId], details.trackId, { ...parameters });
      redactLastParameters(parametersAndResultId, details.trackId);
      await debounceRefreshDataSource(details, parametersAndResultId, parameters);
    }
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function createRecord(details: { dataSourceId: string, trackId: string, miniAppId: string,
    record: Task, dataSetId?: string }): Promise<DataSourceResult | undefined> {
    const parametersAndResultId: string = details.dataSetId ?? details.dataSourceId;
    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }
    // If this isn't  the initial version (used in Galileo), then ignore the request
    if ((dataSource.dataSourceVersion ?? 1) > 1) {
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);

    const createUrl: string = config.url;
    if (!createUrl || createUrl.length === 0) {
      return;
    }

    const parameters: DataSourceParameters = dataSourceParameters.value[parametersAndResultId]?.[details.trackId];
    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(createUrl, dataSource, details.trackId, config, parameters);

    integratorsLog.debug(`[${dataSource.name}] Creating record for source: ${details.dataSourceId}`);
    try {
      const copyOfParameters = { ...parameters };
      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
      const result: DataSourceResult =
        await DataWorker.instance().dispatch('DataSources/createRecord', 'me',
          details.dataSourceId, details.trackId, details.miniAppId, details.record, inputs, guestId);

      result.parameters = copyOfParameters;
      const config: DataSourceConfig = JSON.parse(dataSource.config);
      if (config.resetOnCreate) {
        setData({
          id: parametersAndResultId,
          trackId: details.trackId,
          result: result
        });
      } else {
        addDataSourceRecord({
          id: parametersAndResultId,
          trackId: details.trackId,
          result: result
        });
      }

      return result;
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error creating record in dataSource: ${details.dataSourceId}`);

      throw e;
    }
  }

  async function appAuthLogout(details: { tokenId: string, guestId?: string }): Promise<void> {
    await DataWorker.instance().dispatch('DataSources/appAuthLogout', details.tokenId, details.guestId);
  }

  async function executeAppAuth(details: { dataSourceId: string, trackId: string, miniAppId: string,
    record: Task, dataSetId?: string, parameters: DataSourceParameters }): Promise<AppAuthResult | undefined> {
    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] App auth: Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);

    const createUrl: string = config.url;
    if (!createUrl || createUrl.length === 0) {
      return;
    }

    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(createUrl, dataSource, details.trackId, config, details.parameters);

    integratorsLog.debug(`[${dataSource.name}] Executing app auth for source: ${details.dataSourceId}`);
    try {
      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
      const result: AppAuthResult =
        await DataWorker.instance().dispatch('DataSources/executeAppAuth', 'me',
          details.dataSourceId, details.trackId, details.miniAppId, details.record, inputs, guestId);
      // Store successful auth token in localstorage
      if (result.success) {
        storeAppAuthTokenInLocalStorage(details.miniAppId, details.trackId, result);
      } else {
        const trackIdToUse = guestId ? details.trackId : undefined;
        removeAppAuthTokenFromLocalStorage(details.miniAppId, trackIdToUse);
      }
      return result;
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error executing app auth in dataSource: ${details.dataSourceId}`);

      throw e;
    }
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function updateRecordWithDataset(details: { dataSourceId: string, dataSetId: string, readDataSetId: string,
    trackId: string,
    miniAppId: string, record: Task }):
    Promise<DataSourceResult | undefined> {
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }
    // If this isn't  the initial version (used in Galileo), then ignore the request
    if ((dataSource.dataSourceVersion ?? 1) > 1) {
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);
    const updateUrl: string = config.url;

    const parameters: DataSourceParameters = dataSourceParameters.value[details.dataSetId][details.trackId];
    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(updateUrl, dataSource, details.trackId, config, parameters);
    if (!inputs) {
      return;
    }

    integratorsLog.debug(`[${dataSource.name}] Updating record for source: ${details.dataSourceId}`);
    try {
      // Set data in store before firing of remote request so that local ui shows changes.
      const existingDatasourceResult: DataSourceResult = data.value[details.readDataSetId][details.trackId];
      if (existingDatasourceResult && existingDatasourceResult.records &&
        existingDatasourceResult.records.length === 1) {
        // Copy existing data source result and replace properties with record properties.
        const toSetLocal = cloneDataSourceResult(existingDatasourceResult);
        if (toSetLocal.records) {
          toSetLocal.records[0].properties = {};
          for (const [key, val] of Object.entries(details.record.properties)) {
            toSetLocal.records[0].properties[key] = val;
          }
        }
        setData({
          id: details.record.tableId,
          trackId: details.trackId,
          result: toSetLocal
        });
      }

      // Remove any fields which do not exist in datasource payload fields.
      const taskToSend = cloneTask(details.record);
      const fieldNamesToRemove: string[] = [];
      if (config && config.payload && config.payload.fields) {
        for (const key of Object.keys(taskToSend.properties)) {
          let found = false;
          for (const fieldName of config.payload.fields) {
            if (fieldName.name === key) {
              found = true;
              break;
            }
            if (!found) {
              fieldNamesToRemove.push(key);
            }
          }
        }
      }
      for (const propToRemove of fieldNamesToRemove) {
        delete taskToSend.properties[propToRemove];
      }

      const preUpdate = cloneDataSourceResult(existingDatasourceResult);
      try {
        const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
        const result: DataSourceResult =
          await DataWorker.instance().dispatch('DataSources/updateRecord', 'me',
            details.dataSourceId, details.trackId, details.miniAppId, taskToSend, inputs, guestId);
        return result;
      } catch (error) {
        integratorsLog.debug(`[${dataSource.name}] Error in DataSources/updateRecord: ${details.dataSourceId}`);
        // Set local data back if call to update fails.
        setData({
          id: details.record.tableId,
          trackId: details.trackId,
          result: preUpdate
        });
      }
    } catch (e) {
      integratorsLog.debug(`[${dataSource.name}] Error updating record in: ${details.dataSourceId}`);
      throw e;
    }
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function updateRecord(details: { dataSourceId: string, trackId: string, miniAppId: string, record: Task,
    dataSetId?: string }): Promise<DataSourceResult | undefined> {
    const parametersAndResultId: string = details.dataSetId ?? details.dataSourceId;
    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }
    // If this isn't  the initial version (used in Galileo), then ignore the request
    if ((dataSource.dataSourceVersion ?? 1) > 1) {
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);

    const updateUrl: string = config.url;
    if (!updateUrl || updateUrl.length === 0) {
      return;
    }

    const parameters: DataSourceParameters = dataSourceParameters.value[parametersAndResultId]?.[details.trackId];
    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(updateUrl, dataSource, details.trackId, config, parameters);
    if (!inputs) {
      return;
    }

    integratorsLog.debug(`[${dataSource.name}] Updating record for source: ${details.dataSourceId}`);
    try {
      const copyOfParameters = { ...parameters };
      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
      const result: DataSourceResult =
        await DataWorker.instance().dispatch('DataSources/updateRecord', 'me',
          details.dataSourceId, details.trackId, details.miniAppId, details.record, inputs, guestId);

      result.parameters = copyOfParameters;
      updateDataSourceRecord({
        id: parametersAndResultId,
        trackId: details.trackId,
        result: result
      });

      return result;
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error updating record in: ${details.dataSourceId}`);

      throw e;
    }
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function patchRecord(details: { dataSourceId: string, trackId: string, miniAppId: string, record: Task,
    dataSetId?: string }): Promise<DataSourceResult | undefined> {
    const parametersAndResultId: string = details.dataSetId ?? details.dataSourceId;
    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }
    // If this isn't  the initial version (used in Galileo), then ignore the request
    if ((dataSource.dataSourceVersion ?? 1) > 1) {
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);

    const patchUrl: string = config.url;
    if (!patchUrl || patchUrl.length === 0) {
      return;
    }

    const parameters: DataSourceParameters = dataSourceParameters.value[parametersAndResultId]?.[details.trackId];
    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(patchUrl, dataSource, details.trackId, config, parameters);
    if (!inputs) {
      return;
    }

    integratorsLog.debug(`[${dataSource.name}] Patching record for source: ${details.dataSourceId}`);
    try {
      const copyOfParameters = { ...parameters };
      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
      const result: DataSourceResult =
        await DataWorker.instance().dispatch('DataSources/patchRecord', 'me',
          details.dataSourceId, details.trackId, details.miniAppId, details.record, inputs, guestId);

      result.parameters = copyOfParameters;
      updateDataSourceRecord({
        id: parametersAndResultId,
        trackId: details.trackId,
        result: result
      });

      return result;
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error patching record in: ${details.dataSourceId}`);

      throw e;
    }
  }

  /**
   * In the context of external APIs this is for a data source with v1 config only (e.g. galileo).
   * See {@link invokeExternalApi} for v2+ external API data source invocation
   */
  async function deleteRecord(details: { dataSourceId: string, trackId: string, miniAppId: string,
    dataSetId?: string }): Promise<DataSourceResult | undefined> {
    const parametersAndResultId: string = details.dataSetId ?? details.dataSourceId;
    // check config of data source
    const dataSource: DataSource = dataSourcesById.value[details.dataSourceId];
    if (!dataSource) {
      integratorsLog.debug(`[DataSource] Unknown data source: ${details.dataSourceId}`);
      return undefined;
    }
    // If this isn't  the initial version (used in Galileo), then ignore the request
    if ((dataSource.dataSourceVersion ?? 1) > 1) {
      return undefined;
    }

    const config: RestDataSourceConfig = (JSON.parse(dataSource.config) as RestDataSourceConfig);

    const deleteUrl: string = config.url;
    if (!deleteUrl || deleteUrl.length === 0) {
      return;
    }

    const parameters: DataSourceParameters = dataSourceParameters.value[parametersAndResultId]?.[details.trackId];
    const inputs: Record<string, string> | undefined =
      getDataSourceInputs(deleteUrl, dataSource, details.trackId, config, parameters);

    if (!inputs) {
      return;
    }

    integratorsLog.debug(`[${dataSource.name}] Deleting record from source: ${details.dataSourceId}`);
    try {
      const copyOfParameters = { ...parameters };
      const guestId: string | undefined = userStore.guestIdForTrack(details.trackId);
      const result: DataSourceResult =
        await DataWorker.instance().dispatch('DataSources/deleteRecord', 'me',
          details.dataSourceId, details.trackId, details.miniAppId, inputs, guestId);

      result.parameters = copyOfParameters;
      deleteDataSourceRecord({
        id: parametersAndResultId,
        trackId: details.trackId,
        result: result
      });

      return result;
    } catch (e) {
      integratorsLog.error(`[${dataSource.name}] Error deleting record from: ${details.dataSourceId}`);

      throw e;
    }
  }

  async function getDataSources(tenantId: string, miniAppId?: string): Promise<DataSource[]> {
    // The server needs to be able to differentiate between 'only sources defined directly against the tenant' and
    // out-of-date clients that are not sending the miniAppId and therefore need all sources for the tenant and its apps
    const miniAppIdForRequest: string = miniAppId ?? 'none';
    const sources = await DataWorker.instance().dispatch('DataSources/getDataSources', tenantId, miniAppIdForRequest);
    if (tenantId === 'me') {
      setNewDataSources(sources, miniAppId);
    }
    return sources;
  }

  async function createDataSource(details: { tenantId: string, source: DataSource }): Promise<DataSource> {
    return await DataWorker.instance().dispatch('DataSources/createDataSource', details.tenantId, details.source);
  }

  async function updateDataSource(details: { tenantId: string, id: string, source: DataSource }): Promise<DataSource> {
    return await DataWorker.instance().dispatch(
      'DataSources/updateDataSource', details.tenantId, details.id, details.source);
  }

  async function deleteDataSource(details: { tenantId: string, id: string }): Promise<void> {
    return await DataWorker.instance().dispatch('DataSources/deleteDataSource', details.tenantId, details.id);
  }

  async function importDataSources(details: { tenantId: string, importRequest: DataSourceImportRequest }):
    Promise<DataSourceImportResponse> {
    return await DataWorker.instance().dispatch('DataSources/importDataSources', details.tenantId,
      details.importRequest);
  }

  async function clearDataSourceCache(details: { tenantId: string, id: string }): Promise<void> {
    return await DataWorker.instance().dispatch('DataSources/clearDataSourceCache', details.tenantId, details.id);
  }

  async function invokeExternalApi(details: { dataSourceId: string, payload: ExternalApiRequest, guestId?: string }):
    Promise<ExternalApiResponse> {
    return await DataWorker.instance().dispatch('DataSources/invokeExternalApi', details.dataSourceId,
      details.payload, details.guestId);
  }

  function getLabelsForTrack(track: Track): Label[] {
    const trackEnvironmentId = track.environmentId;
    const owningAppId = track.owningMiniAppId;
    if (trackEnvironmentId != null) {
      // Use the environment specific labels
      const deployedAppStore = useDeployedAppsStore();
      const deployedApp = deployedAppStore.availableDeployedApps.find(app => app.environmentId === trackEnvironmentId);
      if (deployedApp) {
        return deployedApp.currentRelease.workspaceFields;
      } else {
        // No deployed app in the environment this track is in
        return [];
      }
    } else if (owningAppId) {
      const tasksStore = useTasksStore();
      const miniApps: MiniApp[] = tasksStore.referencedTenantApps;
      const owningApp = miniApps.find(a => a.id === owningAppId);
      if (!owningApp) {
        return [];
      }
      return owningApp.workspaceLabels;
    } else {
      const tenantsStore = useTenantsStore(pinia);
      const tenant: Tenant = tenantsStore.allTenants.filter(
        (current: Tenant) => current.id === track.tenantId)[0];
      return tenant.labels ?? [];
    }
  }

  function recordEquals(a: Record<string, string>, b: Record<string, string>): boolean {
    if (a === b) {
      return true;
    }

    if (!a || !b) {
      return false;
    }

    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    for (const key of Object.keys(a)) {
      if (a[key] !== b[key]) {
        return false;
      }
    }

    return true;
  }

  /**
   * Returns a map of datasource URL placeholder strings -> value for that placeholder
   * The placeholder strings are '%holder%' tokens in a datasource configuration
   */
  function getDataSourceInputs(url: string | null, dataSource: DataSource,
    trackId: string, config: RestDataSourceConfig, dataSourceParameters: DataSourceParameters):
    Record<string, string> | undefined {
    const inputs: Record<string, string> = {};
    if (!dataSourceParameters) {
      dataSourceParameters = {};
    }
    try {
      const tracksStore = useTracksStore(pinia);
      const track: Track | undefined = tracksStore.tracks[trackId];

      const tenantLabels = track ? getLabelsForTrack(track) : [];

      let missing: string = '';
      let ready: boolean = true;

      if (config.requestHeaders) {
        populateRequestHeaders(missing, config.requestHeaders, dataSourceParameters, inputs);
      }

      if (config.urlParameters) {
        for (const param of config.urlParameters) {
          let fromLabel: string | undefined;

          if (param.fromType && param.fromType === 'label') {
            fromLabel = param.from ? param.from : param.name;
          } else if (param.fromLabel) {
            fromLabel = param.fromLabel;
          }

          if (fromLabel && track) {
            // we're expecting a label to be present for this parameter
            const tenantLabel: Label | undefined =
              tenantLabels.find((label: Label) => label.name === fromLabel);

            if (tenantLabel) {
              const label: TrackLabel | undefined =
                track.trackLabels?.find((label: TrackLabel) => { return label.labelId === tenantLabel.id; });
              if (label) {
                // this check is to allow for values being set as blank. The tasks data structure (that
                // the data sources have to comply to) use the value of empty string as "not set". To allow
                // the custom view and data sources to be able to set a value to indicate its been set to blank,
                // the value of ' ' - one space - is used to indicate blank setting and then it's trimmed out below
                // This horrible hack is required to support what we're trying to achieve right now but is
                // an ideal candidate for reworking.
                if (!label.value) {
                  // this is normal operation, value is not set yet so just don't get the data source.
                  missing += fromLabel + ',';
                  ready = false;
                } else {
                  inputs[param.name] = label.value.trim();
                }
              } else {
                missing += fromLabel + ',';
                ready = false;
              }
            }
          } else {
            // we're expecting a data source parameter to be set
            const value: string | undefined = dataSourceParameters[param.name];
            // this check is to allow for values being set as blank. The tasks data structure (that
            // the data sources have to comply to) use the value of empty string as "not set". To allow
            // the custom view and data sources to be able to set a value to indicate its been set to blank,
            // the value of ' ' - one space - is used to indicate blank setting and then it's trimmed out below
            // This horrible hack is required to support what we're trying to achieve right now but is
            // an ideal candidate for reworking.
            if (!value) {
              // this is normal operation, value is not set yet so just don't get the data source.
              missing += param.name + ',';
              ready = false;
            } else {
              inputs[param.name] = value.trim();
            }
          }
        }
      }

      if (url) {
        // TODO: Migrate away from '%' as the variable delimiter
        // Because we use '%' as a delimiter, but this is used in URL encoding, we have to build up a list of possible
        // values that could be referenced in the URL in order to work out if any exist.
        const paramNames: string[] = [];
        config.urlParameters?.forEach(param => paramNames.push(param.name));
        config.payload?.fields?.forEach(field => paramNames.push(field.name));
        config.substitutionParameters?.forEach(param => paramNames.push(param.name));
        let requiredParameters: RegExpMatchArray | null = null;
        if (paramNames.length) {
          const regex: string = `%(?:(?:${paramNames.join(')|(?:')}))%`;
          // URL substitutions are still supported for indexes etc in the URL, however they
          // prefer data source parameter values over workspace labels
          requiredParameters = url.match(RegExp(regex, 'g'));
        }
        if (requiredParameters) {
          for (let req of requiredParameters) {
            // take off the % signs
            req = req.substring(1, req.length - 1);

            // we're expecting a data source parameter to be set
            const value: string | undefined = dataSourceParameters[req];
            if (value) {
              inputs[req] = value.trim();
            } else {
              const tenantLabel: Label | undefined =
                tenantLabels.find((label: Label) => { return label.name === req; });

              if (tenantLabel) {
                const label: TrackLabel | undefined =
                  track.trackLabels?.find((label: TrackLabel) => { return label.labelId === tenantLabel.id; });
                if (label) {
                  // this check is to allow for values being set as blank. The tasks data structure (that
                  // the data sources have to comply to) use the value of empty string as "not set". To allow
                  // the custom view and data sources to be able to set a value to indicate its been set to blank,
                  // the value of ' ' - one space - is used to indicate blank setting and then it's trimmed out below
                  // This horrible hack is required to support what we're trying to achieve right now but is
                  // an ideal candidate for reworking.
                  if (!label.value) {
                    // this is normal operation, value is not set yet so just don't get the data source.
                    missing += req + ',';
                    ready = false;
                  } else {
                    inputs[tenantLabel.name] = label.value.trim();
                  }
                } else {
                  missing += req + ',';
                  ready = false;
                }
              } else {
                integratorsLog.debug(`[${dataSource.name}] Data source ${dataSource.id} (${dataSource.name})` +
                  ` has a required input that is not a workspace label: ${req}`);
                ready = false;
              }
            }
          }
        }
      }

      if (!ready) {
        if (missing.length > 0) {
          integratorsLog.debug(`[${dataSource.name}] Data source ${dataSource.id} (${dataSource.name})` +
            ' has required input(s) that are not configured on this workspace' +
            `: ${missing}`);
        }

        if (config.method === 'POST' || config.method === 'PUT' || config.method === 'PATCH') {
          return inputs;
        } else {
          // Return what we have so far and don't block the request
          return undefined;
        }
      }

      // the URL may be ready to access at this point - create it and test it to make sure
      if (url) {
        let targetUrl: string = url;
        for (const key of Object.keys(inputs)) {
          targetUrl = targetUrl?.replaceAll('%' + key + '%', inputs[key]);
        }

        try {
          const url = new URL(targetUrl);
          integratorsLog.debug(`[${dataSource.name}] Access data source on : ${url}`);
        } catch (e) {
          integratorsLog.debug(`[${dataSource.name}] Data source ${dataSource.id}` +
            ` appears to have valid input parameters but the URL generated is invalid ${targetUrl}`);
          return undefined;
        }
      }
    } catch (e) {
      integratorsLog.error(e);
      integratorsLog.error(`[${dataSource.name}] Error retrieving records from ` +
        `data source ${dataSource.id}` +
        `. Invalid config? \n\n ${dataSource.config}`);
      return undefined;
    }

    // make sure any data source parameters we have been given get passed to the
    // server so they can be used in other functions like authentication
    for (const key in dataSourceParameters) {
      if (!inputs[key]) {
        inputs[key] = dataSourceParameters[key];
      }
    }

    return inputs;
  }

  /** Populates the values for any Request Headers that are required */
  function populateRequestHeaders(missing: string, valueConfig: DataSourceConfigParam[],
    dataSourceParameters: DataSourceParameters, inputs: Record<string, string>) {
    for (const source of valueConfig) {
      const value: string | undefined = dataSourceParameters[source.name];

      if (!value) {
        missing += source.name + ',';
      } else {
        inputs[source.name] = value.trim();
      }
    }
  }

  return {
    dataSources,
    appDataSourcesByAppId,
    tenantDataSources,
    lastParameters,
    data,
    dataSourceParameters,
    dataSourcesById,
    refreshDataSourcesMetaData,
    clearData,
    clearDataSourceParameters,
    updateRedactedParameters,
    updateDataSourceParameter,
    refreshDataSourceForTrack,
    updateRecordWithDataset,
    createRecord,
    updateRecord,
    patchRecord,
    deleteRecord,
    getDataSources,
    createDataSource,
    updateDataSource,
    deleteDataSource,
    clearDataSourceCache,
    invokeExternalApi,
    executeAppAuth,
    appAuthLogout,
    importDataSources,
  };
});
