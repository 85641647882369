import { MeetingLockType } from '@/custom_typings/cafexmeetings/meetings-api';
import { ChatSummary } from '@/data/datatypes/ChatSummary';
import { BatchMemberDetails } from '@/data/datatypes/Member';
import { TabInfo } from '@/data/datatypes/UI/TabbedNavigation';

import { ChatMessage } from './chat/ChatMessage';
import TrackLabel from './TrackLabel';

export interface TrackWebhook {
  id?: string;
  externalId: string;
  label: string;
  eventName: string;
}

export enum ChatAccessLevel {
  ANYONE = 'ANYONE',
  NON_GUESTS = 'NON_GUESTS',
  COLLABORATORS = 'COLLABORATORS',
  COORDINATORS = 'COORDINATORS',
  NOONE = 'NOONE',
}

export enum ActivityType {
  NEW_ENTRY = 'NEW_ENTRY',
  ENTRY_COMMENT = 'ENTRY_COMMENT',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  TRACK_TRANSFER_REQUEST = 'TRACK_TRANSFER_REQUEST',
  MEMBERSHIP_REQUEST = 'MEMBERSHIP_REQUEST',
  SCHEDULE_MEETING = 'SCHEDULE_MEETING',
  MEETING = 'MEETING',
  MEETING_COMMENT = 'MEETING_COMMENT',
  TASK_MODIFIED = 'TASK_MODIFIED',
  THREAD_CHAT_MESSAGE = 'THREAD_CHAT_MESSAGE',
  NON_TASK_RECORD_MODIFIED = 'NON_TASK_RECORD_MODIFIED',
}

export enum TrackType {
  STANDARD = 'STANDARD',
  USER = 'USER',
  ADHOC_MEETING = 'ADHOC_MEETING',
  SCHEDULED_MEETING = 'SCHEDULED_MEETING',
  PRIVATE_CHAT = 'PRIVATE_CHAT',
  CHANNEL_CHAT = 'CHANNEL_CHAT',
  BREAKOUT = 'BREAKOUT',
  UNKNOWN = 'UNKNOWN',
  TEMPLATE = 'TEMPLATE',
}

export enum Discoverability {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum ShareableContent {
  DEFAULT = 'DEFAULT',
  ALL = 'ALL',
  APPROVED = 'APPROVED',
  NONE = 'NONE',
}

export enum CallMessageType {
  CALL = 'CALL',
  CALL_ANSWER = 'CALL_ANSWER',
  CALL_REJECT = 'CALL_REJECT',
  CALL_CANCEL = 'CALL_CANCEL',
}

export interface LimitedTrack {
  id: string;
  title: string;
  subtitle: string;
  owner: string;
  type: string;
  status: string;
  tenantId: string;
  owningMiniAppId?: string;
}

export interface TrackDetails {
  created?: number;
  title?: string;
  subtitle?: string;
  email?: string;
  owner?: string;
  summary?: string;
  templateTrackId?: string;
  icon?: string | File | URL;
  color?: string;
  prefix?: string;
  chatSessionId?: string;
  autoRecord?: boolean;
  trackLabels?: TrackLabel[];
  type: TrackType;
  pendingTransferTarget?: string;
  parentTrackId?: string;
  preselectedEntryId?: string;
  userFavourite?: boolean;
  typeMetadata?: string;
  lastChatMessage?: ChatMessage;
  numUnseenChatMessages?: number;
  discoverability?: Discoverability;
  publishable?: boolean;
  allowPublishing?: boolean;

  lastViewed?: number;
  lastUpdated?: number;

  // Time at which the next meeting starts
  nextMeetingTime?: number;
  // Time at which the next meeting ends
  nextMeetingEndTime?: number;
  nextMeetingId?: string;
  nextMeetingTitle?: string;

  creationEndpointName?: string;
  creationEndpointPreventDuplicates?: boolean;

  // eslint-disable-next-line no-use-before-define
  activity?: TrackActivity[];
  webhooks?: TrackWebhook[];

  defaultWaitingRoomType?: MeetingLockType;
  shareableContent?: ShareableContent;

  chatSettingViewMessages?: ChatAccessLevel;
  chatSettingSendMessages?: ChatAccessLevel;

  chatSummaries?: ChatSummary[];
  enabledNavigationTabs?: TabInfo[];

  autoArchiveHours?: number;
  owningMiniAppId?: string; // The app that this template/workspace belongs to (for grouping tracks by top-level app)

  environmentId?: string;
}

export interface Track extends TrackDetails {
  id: string;
  tenantId: string;
}

export interface GuestTrack extends Track {
  tenantChatSettingViewMessages: ChatAccessLevel;
  tenantChatSettingSendMessages: ChatAccessLevel;
  tenantAllowsChatSettingOverrides: boolean;
}

export interface TrackActivity {
  type: ActivityType;
  date: number;
  userId: string;
  userName?: string;
  message: string;
  entryId?: string;
  track: Track;
  canDelete: boolean;
  actionId?: string;
  targetId?: string;
  tableId?: string;
}

export interface TrackBatch {
  track: Track;
  members: BatchMemberDetails[];
}

export interface CustomNotification extends Partial<TrackActivity> {
  title?: string;
  trackType?: TrackType;
  nextMeetingId?: string;
  location?: string;
  description?: string;
  nextStart?: number;
}

export interface EntryComment {
  type: ActivityType;
  entryId?: string;
  track: Track;
}

export enum TrackStatus {
  ACTIVE = 0,
  ARCHIVED = 1,
}

export interface TrackListSort {
  by: 'ID' | 'OWNER_ID' | 'CREATED_DATE' | 'STATUS' | 'TYPE';
  direction: 'ASC' | 'DESC';
}

export interface TrackListRequest {
  page: TrackListPage;
  id?: string;
  ownerId?: string;
  status?: TrackStatus;
  type?: TrackType;
  from: Date;
  to: Date;
  sortBy: TrackListSort;
}

export interface TrackListPage {
  pageSize: number;
  continuationToken?: string;
  resultCount?: number;
}

export interface TrackListResponse {
  page: TrackListPage;
  tracks: LimitedTrack[];
}
